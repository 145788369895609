import apiUtils from '../Utils/ApiUtils';
import getApiHost from '../Utils/UrlUtils';

export default function IntroCeeAPI() {
  const utils = apiUtils();

  return {
    getCoverSession() {
      return utils
        .getNoAuth(`${getApiHost()}/cover-session/`, {
          withCredentials: true,
        });
    },
    createAccount(data) {
      return utils
        .postNoAuth(`${getApiHost()}/users/`, data, {
          withCredentials: true,
        });
    },
    confirmEmail(code) {
      return utils.postNoAuth(`${getApiHost()}/users/confirm-email/${code}/`);
    },
    getPayment(id) {
      return utils.get(`${getApiHost()}/payments/${id}/`);
    },
    getActivity() {
      return utils.get(`${getApiHost()}/bar/activity/`);
    },
    settings() {
      return utils.get(`${getApiHost()}/settings/`);
    },
    signUp(data) {
      return utils
        .post(`${getApiHost()}/participants/signup/`, data);
    },
    topUp(value) {
      return utils
        .post(`${getApiHost()}/bar/topup/`, {
          value,
        });
    },
    getTermsAndConditions() {
      return utils.getNoAuth(`${getApiHost()}/terms-and-conditions/`);
    },
    sendPasswordResetEmail(email) {
      return utils.postNoAuth(`${getApiHost()}/password-reset/`, { email });
    },
    validateResetPasswordToken(token) {
      return utils.postNoAuth(`${getApiHost()}/password-reset/validate_token/`, { token });
    },
    resetPassword(token, password) {
      return utils.postNoAuth(`${getApiHost()}/password-reset/confirm/`, { token, password });
    },
    postSomething(data) {
      return utils
        .post(`${getApiHost()}/tasks/`, data);
    },
  };
}
