import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

export const NotificationContext = createContext();

export default function NotificationProvider({ children }) {
  const [notificationsManager] = useState(NotificationManager);

  return (
    <NotificationContext.Provider value={notificationsManager}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
