import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import PageHeader from '../Components/PageHeader';
import IntroCeeAPI from '../APIs/IntroCeeAPI';

function TermsAndConditions() {
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const api = IntroCeeAPI();

  useEffect(() => {
    api.getTermsAndConditions().then((response) => {
      setTermsAndConditions(response.data.terms_and_conditions);
    });
  }, []);

  return (
    <div>
      <PageHeader title="Terms and Conditions" />
      <Container maxWidth="lg">
        {/* eslint-disable-next-line */}
        <div className="terms-and-conditions" dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
      </Container>
    </div>
  );
}

export default TermsAndConditions;
