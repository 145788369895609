import React from 'react';
import { Button, Container } from '@mui/material';
import { TbHome } from 'react-icons/tb';

function NotFound() {
  return (
    <Container maxWidth="lg">
      <div className="not-found">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </p>
        <Button
          className="btn btn-vanilla"
          startIcon={<TbHome />}
          href="/"
        >
          Back to Homepage
        </Button>
      </div>
    </Container>
  );
}

export default NotFound;
