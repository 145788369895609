import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {
  Button, Container, InputAdornment, TextField,
} from '@mui/material';
import { TbArrowRight } from 'react-icons/tb';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import PageHeader from '../../Components/PageHeader';
import IntroCeeAPI from '../../APIs/IntroCeeAPI';

function TopUp() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [topUp, setTopUp] = useState(0.00);
  const api = IntroCeeAPI();

  useEffect(() => {
    if (user && !user.is_signed_up_barbecue && !user.is_signed_up_weekend) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You are not signed up for the Introduction Weekend or Barbecue. Please sign up to one of the activities first.',
      });
      navigate('/account');
    }
  }, [user]);

  const topUpWallet = () => {
    api.topUp(parseFloat(topUp).toFixed(2)).then((res) => {
      // eslint-disable-next-line
      window.location.href = res.data.payment.details._links.checkout.href;
    });
  };

  return (
    <div className="topup">
      <PageHeader title="Top Up" />
      <Container maxWidth="lg">
        <label>Amount</label>
        <p>
          During the weekend & barbecue you will only be able to pay at the bar
          using your wallet, so make sure to top up in advance. At the end of
          the introduction period, we will reimburse all funds left over in your
          wallet. You can avoid to pay multiple €0.5 commissions if you top up more
          in the beginning.
        </p>
        <TextField
          className="amount-input"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
            inputProps: { min: 0, step: 0.5 },
          }}
          value={topUp}
          onChange={(e) => setTopUp(e.target.value)}
        />
        <small>+€1 transaction costs</small>
        <br />
        <Button
          className="btn btn-purple"
          startIcon={<TbArrowRight />}
          onClick={topUpWallet}
          sx={{ mt: 2 }}
        >
          Continue
        </Button>
      </Container>
    </div>
  );
}

export default TopUp;
