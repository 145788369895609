import React from 'react';
import {
  Button, Container, Grid,
} from '@mui/material';
import { TbBeach, TbGrill } from 'react-icons/tb';
import Marquee from 'react-marquee-slider';
import Fact from '../../Components/Fact';
import LightBoxImage from '../../Components/LightBoxImage';
import PackingList from '../../Components/PackingList';

function HomePage() {
  const scrollToBarbecue = () => {
    const element = document.getElementById('barbecue');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToWeekend = () => {
    const element = document.getElementById('weekend');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="homepage">
      <div className="hero">
        <Container maxWidth="lg">
          <img src="/rug_logo_white.png" alt="RUG logo" className="rug" />
          <span className="hero-subtitle">AI, CS & CSS — September 2024</span>
          <h1 className="hero-title">Introduction Week</h1>
          <span className="outline-headline">Introduction Week</span>
          <span className="outline-headline">Introduction Week</span>
          <p>
            Get to know your fellow students and make friends from your first university
            week, while getting accustomed to the student life, the study association
            (Cover) and enjoying a week full of activities organised by by students for students.
          </p>
        </Container>
        <div className="bands">
          <img src="/bands.png" alt="Bands" />
        </div>
        <img src="/circle-text.svg" alt="Circle Text" className="circle-text" />
      </div>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <h2>Events</h2>
        <h3>What the Introduction Week is all about.</h3>
        <Grid container spacing={6} sx={{ mt: -2 }}>
          <Grid item xs={12} md={4}>
            {/* eslint-disable-next-line */}
            <div className="event-block barbecue" onClick={scrollToBarbecue}>
              <h4>🌭 Introduction Barbecue</h4>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* eslint-disable-next-line */}
            <div className="event-block weekend" onClick={scrollToWeekend}>
              <h4>🏖️ Introduction Weekend</h4>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="event-block social">
              <h4>🎉 Kick-Off Social</h4>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={6} sx={{ mt: 13 }} alignItems="center" id="barbecue">
          <Grid item xs={12} md={6}>
            <h2 className="event-title">🌭 Introduction Barbecue</h2>
            <p>
              After your first 3 days at uni, it&apos;s time to have a relaxing
              meal at the &quot;great garden&quot; of the Bernoulliborg (the big blue
              building😉). A moment where you cannot only enjoy the finest
              Dutch weather whilst getting your belly stuffed, but also
              an opportunity to socialize and meet your fellow students!
              No matter if you want to enjoy a vegetarian or
              non-vegetarian meal, there is an option for you!
              <br />
              <br />
              Will we see you there 🙋?
            </p>
            <Button
              className="btn btn-purple"
              startIcon={<TbGrill />}
              sx={{ mt: 2 }}
              href="/signup"
            >
              Sign Up
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <Fact value="September 4, 17:00" title="Date & Time" icon="📅" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Fact value="250 students" title="Capacity" icon="💕️" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Fact value="Zernike Campus" title="Location" icon="🗺️️" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Fact value="€ 5.00" title="Price" icon="💸" />
              </Grid>
              <Grid item xs={12} className="event-image">
                <LightBoxImage image="/barbecue_featured.jpeg" alt="Barbecue" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className="marquee">
        <Marquee velocity={25}>
          <img src="/barbecue_1.jpeg" alt="Barbecue" />
          <img src="/barbecue_2.jpeg" alt="Barbecue" />
          <img src="/barbecue_3.jpeg" alt="Barbecue" />
          <img src="/barbecue_4.jpeg" alt="Barbecue" />
          <img src="/barbecue_5.jpeg" alt="Barbecue" />
          <img src="/barbecue_6.jpeg" alt="Barbecue" />
          <img src="/barbecue_7.jpeg" alt="Barbecue" />
        </Marquee>
      </div>
      <Container maxWidth="lg">
        <Grid container spacing={6} sx={{ mt: 13 }} alignItems="center" id="weekend">
          <Grid item xs={12} md={6} order={{ xs: 2, lg: 1 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <Fact value="September 6 - 8" title="Date" icon="📅" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Fact value="150 students" title="Capacity" icon="💞" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Fact value="Stadskanaal" title="Location" icon="🗺️️" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Fact value="€ 54.00" title="Price" icon="💸" />
              </Grid>
              <Grid item xs={12} className="event-image">
                <LightBoxImage image="/weekend_featured.jpeg" alt="Weekend" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, lg: 2 }}>
            <h2 className="event-title">🏖️ Introduction Weekend</h2>
            <p>
              The Cover Introductory Camp is a weekend dedicated to the new
              students of Artificial Intelligence and Computing Science
              at the University of Groningen.
              <ul>
                <li>
                  Get to know your fellow students and make
                  friends from your first university weekend. 📆
                </li>
                <li>
                  A camp organised by students for students. The organisers
                  are the Introduction Committee of Cover, the Study
                  Association for AI & CS at the UG. 🤖
                </li>
                <li>
                  This weekend full of games, adventure, parties and
                  fun will help you discover the association, your
                  programme, your cohort and student traditions! 🥳
                </li>
              </ul>
            </p>
            <Button
              className="btn btn-purple"
              startIcon={<TbBeach />}
              sx={{ mt: 2, mr: 3 }}
              href="/signup"
            >
              Sign Up
            </Button>
            <PackingList />
          </Grid>
          <Grid item xs={12} md={12} order={{ xs: 3 }}>
            <h2 className="event-title">What&apos;s included in the price?</h2>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item xs={12} md={3}>
                <Fact icon="🚌" title="" value="*Transport to and from Stadskanaal" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Fact icon="🍽️" title="" value="2 dinners, 1 lunch and 2 breakfasts" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Fact icon="🛌" title="" value="Accommodation in 6 people rooms (w/ your friends)" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Fact icon="🔥" title="" value="2 parties, a pubquiz and a campfire" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Fact icon="🍺" title="" value="Low bar prices for beer, wine and sodas" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Fact icon="⛳️" title="" value="Plenty of games throughout the weekend" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Fact icon="🇳🇱️" title="" value="An introduction to Dutch student culture" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Fact icon="✨" title="" value="Unforgettable memories with your new friends" />
              </Grid>
            </Grid>
            <p>
              *The buses will depart from the Zernike Campus,
              in front of the Bernoulliborg, on Friday at 15:00.
              The buses will return to the Zernike Campus on
              Sunday at 16:00.
            </p>
          </Grid>
        </Grid>
      </Container>
      <div className="marquee">
        <Marquee velocity={25}>
          <img src="/weekend_1.jpeg" alt="Barbecue" />
          <img src="/weekend_2.jpeg" alt="Barbecue" />
          <img src="/weekend_3.jpeg" alt="Barbecue" />
          <img src="/weekend_4.jpeg" alt="Barbecue" />
          <img src="/weekend_5.jpeg" alt="Barbecue" />
          <img src="/weekend_6.jpeg" alt="Barbecue" />
          <img src="/weekend_7.jpeg" alt="Barbecue" />
          <img src="/weekend_8.jpeg" alt="Barbecue" />
        </Marquee>
      </div>
    </div>
  );
}

export default HomePage;
