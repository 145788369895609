import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Unstable_NumberInput as BaseNumberInput,
} from '@mui/base/Unstable_NumberInput';
import { MuiTelInput } from 'mui-tel-input';
import { TbCar, TbUserHeart, TbUserPlus } from 'react-icons/tb';

function CreateAccountForm({
  formData, setFormData, senior, onSubmit,
}) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <label>
          First Name
          <span className="required">*</span>
        </label>
        <input
          type="text"
          placeholder="Eg. Jelmer"
          value={formData.first_name}
          onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <label>Name Infix</label>
        <input
          type="text"
          placeholder="Eg. van der"
          value={formData.infix}
          onChange={(e) => setFormData({ ...formData, infix: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <label>
          Last Name
          <span className="required">*</span>
        </label>
        <input
          type="text"
          placeholder="Eg. Velde"
          value={formData.last_name}
          onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <label>
          Email Address
          <span className="required">*</span>
        </label>
        <input
          type="email"
          placeholder="Eg. jeroen@svcover.nl"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <label>
          Phone Number
          <span className="required">*</span>
        </label>
        <MuiTelInput
          value={formData.phone}
          onChange={(p) => setFormData({ ...formData, phone: p })}
          defaultCountry="nl"
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <label>
          Date of Birth
          <span className="required">*</span>
        </label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              value={formData.date_of_birth}
              onChange={(d) => setFormData({ ...formData, date_of_birth: d })}
              format="YYYY-MM-DD"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={4}>
        <label>
          Study Phase
          <span className="required">*</span>
        </label>
        <select defaultValue="" onChange={(e) => setFormData({ ...formData, study_phase: e.target.value })}>
          <option disabled value="">Select Study Phase</option>
          <option value="BSc">Bachelor</option>
          <option value="MSc">Master</option>
        </select>
      </Grid>
      <Grid item xs={12} lg={4}>
        <label>
          Study Programme
          <span className="required">*</span>
        </label>
        <select defaultValue="" onChange={(e) => setFormData({ ...formData, study_programme: e.target.value })}>
          <option disabled value="">Select Study Programme</option>
          <option value="Artificial Intelligence">Artificial Intelligence</option>
          <option value="Computing Science">Computing Science</option>
          <option value="Computational Cognitive Science">Computational Cognitive Science</option>
        </select>
      </Grid>
      {senior ? (
        <Grid item xs={12} lg={4}>
          <label>
            Study Year
            <span className="required">*</span>
          </label>
          <BaseNumberInput
            slotProps={{
              incrementButton: {
                children: '▾',
              },
              decrementButton: {
                children: '▾',
              },
            }}
            value={formData.study_year}
            onChange={(_, val) => setFormData({ ...formData, study_year: val })}
            min={1}
            max={10}
          />
          <small>During the Introduction Week</small>
        </Grid>
      ) : (
        <Grid item xs={12} lg={4}>
          <label>
            Student Number
            <span className="required">*</span>
          </label>
          <input
            type="text"
            placeholder="Eg. S0000000"
            value={formData.student_number}
            onChange={(e) => setFormData({ ...formData, student_number: e.target.value })}
          />
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <label>
          Emergency Contact
          <span className="required">*</span>
        </label>
        <input
          type="text"
          placeholder="E.g. John Doe (father)"
          value={formData.emergency_contact}
          onChange={(e) => setFormData({ ...formData, emergency_contact: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <label>
          Emergency Contact Phone
          <span className="required">*</span>
        </label>
        <MuiTelInput
          value={formData.emergency_contact_phone}
          onChange={(p) => setFormData({ ...formData, emergency_contact_phone: p })}
          defaultCountry="nl"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <label>
          Password
          <span className="required">*</span>
        </label>
        <input
          type="password"
          placeholder="Type password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <label>
          Password Confirmation
          <span className="required">*</span>
        </label>
        <input
          type="password"
          placeholder="Type password again"
          value={formData.password_confirmation}
          onChange={(e) => setFormData({ ...formData, password_confirmation: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <label>Dietary Requirements</label>
        <input
          type="text"
          placeholder="E.g. vegetarian, vegan, lactose intolerant, nut allergy, kosher, halal, ..."
          value={formData.dietary_requirements}
          onChange={(e) => setFormData({ ...formData, dietary_requirements: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <label>Remarks</label>
        <textarea
          placeholder="Anything else we should know?"
          value={formData.remarks}
          onChange={(e) => setFormData({ ...formData, remarks: e.target.value })}
          rows={3}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        {senior && (
          <>
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="drivers_license"
                checked={formData.drivers_license}
                onChange={(e) => setFormData({ ...formData, drivers_license: e.target.checked })}
                hidden
              />
              <label htmlFor="drivers_license">
                <TbCar />
                {' '}
                I have a drivers license
              </label>
            </div>
            <div className="checkbox-group" style={{ marginTop: 20, marginBottom: 20 }}>
              <input
                type="checkbox"
                id="mentor"
                checked={formData.mentor}
                onChange={(e) => setFormData({ ...formData, mentor: e.target.checked })}
                hidden
              />
              <label htmlFor="mentor">
                <TbUserHeart />
                {' '}
                I am a mentor.
              </label>
            </div>
          </>
        )}
        <div className="checkbox-group">
          <input
            type="checkbox"
            id="terms_and_conditions"
            checked={formData.terms_and_conditions}
            onChange={(e) => setFormData({ ...formData, terms_and_conditions: e.target.checked })}
            hidden
          />
          <label htmlFor="terms_and_conditions">
            I have read and agree to the
            {' '}
            <a href="/terms-and-conditions" target="_blank" className="nice-link">terms and conditions</a>
            .
          </label>
        </div>
      </Grid>
      <Grid item xs={12} lg={12} sx={{ textAlign: 'center' }}>
        <Button
          className="btn btn-purple btn-large"
          startIcon={<TbUserPlus />}
          onClick={onSubmit}
        >
          Create Account
        </Button>
      </Grid>
    </Grid>
  );
}

CreateAccountForm.propTypes = {
  formData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    infix: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
    study_programme: PropTypes.string,
    student_number: PropTypes.string,
    study_year: PropTypes.number,
    dietary_requirements: PropTypes.string,
    remarks: PropTypes.string,
    emergency_contact: PropTypes.string,
    emergency_contact_phone: PropTypes.string,
    drivers_license: PropTypes.bool,
    mentor: PropTypes.bool,
    terms_and_conditions: PropTypes.bool,
    // eslint-disable-next-line
    date_of_birth: PropTypes.object,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  senior: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

CreateAccountForm.defaultProps = {
  senior: false,
};

export default CreateAccountForm;
