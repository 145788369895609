import React, { useState } from 'react';
import { Lightbox } from 'react-modal-image';
import propTypes from 'prop-types';

function LightBoxImage({ image, alt }) {
  const [lightBoxOpen, setLightBoxOpen] = useState(false);

  return (
    <div>
      {/* eslint-disable-next-line */}
      <img
        src={image}
        alt={alt}
        onClick={() => setLightBoxOpen(true)}
        className="lightbox-image"
      />
      {lightBoxOpen && (
        <Lightbox
          medium={image}
          large={image}
          onClose={() => setLightBoxOpen(false)}
        />
      )}
    </div>
  );
}

LightBoxImage.propTypes = {
  image: propTypes.string.isRequired,
  alt: propTypes.string.isRequired,
};

export default LightBoxImage;
