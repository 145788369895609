import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageHeader from '../../Components/PageHeader';
import IntroCeeAPI from '../../APIs/IntroCeeAPI';
import NotFound from '../NotFound';

function Payment() {
  const [payment, setPayment] = useState(null);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const api = IntroCeeAPI();

  useEffect(() => {
    api.getPayment(id).then((res) => {
      setPayment(res.data);
    }).catch(() => {
      setError(true);
    });
  }, [id]);

  if (error) {
    return <NotFound />;
  }

  if (payment && payment.status === 'PENDING') {
    // eslint-disable-next-line
    window.location.href = payment.details._links.checkout.href;
  }

  return (
    <div className="payment">
      <PageHeader title="Payment" />
      <Container maxWidth="lg">
        {payment ? (
          <>
            <h2>
              {payment.status === 'PAID' && 'Success!'}
              {payment.status === 'PENDING' && 'Redirecting to payment...'}
              {payment.status !== 'PAID' && payment.status !== 'PENDING' && 'Payment failed!'}
            </h2>
            <p>
              {payment.status === 'PAID' && 'Your payment was successful!'}
              {payment.status !== 'PAID'
              && payment.status !== 'PENDING'
              && 'Something went wrong with your payment. Please try signing up or topping up again..'}
            </p>
            <Button
              className="btn btn-purple"
              href="/account"
            >
              Go to My Account
            </Button>
          </>
        ) : (
          <CircularProgress />
        )}
      </Container>
    </div>
  );
}

export default Payment;
