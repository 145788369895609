export function logoutIf401(error, logout) {
  if (error?.response?.status === 401) {
    logout();
  }

  throw error;
}

export function refreshAndRetryIfNeeded(error, originalRequest, refresh, logout) {
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    return refresh()
      .then((newConfig) => originalRequest(newConfig))
      .catch((newError) => logoutIf401(newError, logout));
  }

  throw error;
}

export function mapToAuthRequest(request, getConfig, refresh, logout) {
  return getConfig()
    .then((config) => request(config))
    .catch((error) => refreshAndRetryIfNeeded(error, request, refresh, logout));
}
