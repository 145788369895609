import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Container } from '@mui/material';
import { TbAlertSquareRounded, TbArrowRight } from 'react-icons/tb';
import Swal from 'sweetalert2';
import PageHeader from '../../Components/PageHeader';
import IntroCeeAPI from '../../APIs/IntroCeeAPI';

function PasswordReset() {
  const { token } = useParams();
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const api = IntroCeeAPI();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();

  const sendResetEmail = () => {
    setLoading(true);
    api.sendPasswordResetEmail(email).then(() => {
      setSent(true);
      setLoading(false);
    }).catch(() => {
      setSent(true);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (token) {
      api.validateResetPasswordToken(token).catch(() => setError(true));
    }
  }, []);

  const resetPassword = () => {
    if (password !== passwordConfirm) {
      Swal.fire({
        title: 'Error',
        text: 'Passwords do not match.',
        icon: 'error',
      });
      return;
    }
    api.resetPassword(token, password).then(() => {
      setReset(true);
    }).catch((e) => {
      Swal.fire({
        title: 'Error',
        text: e.response.data.error,
        icon: 'error',
      });
    });
  };

  return (
    <div>
      <PageHeader title="Reset Password" />
      <Container maxWidth="sm">
        {sent ? (
          <Alert
            severity="success"
            icon={<TbAlertSquareRounded />}
            sx={{ mb: 2 }}
          >
            If your email address is associated with an account,
            you will receive an email with instructions to reset
            your password. If you do not receive this email, please
            check your spam folder. Ultiimately, if you do not
            receive the email, please contact us at introcee@svcover.nl.
          </Alert>
        ) : (
          <>
            {token ? (
              <>
                {error ? (
                  <Alert
                    severity="error"
                    sx={{ mb: 2 }}
                  >
                    This password reset link is invalid or has expired.
                  </Alert>
                ) : (
                  <>
                    {reset ? (
                      <Alert
                        severity="success"
                        sx={{ mb: 2 }}
                        action={(
                          <Button
                            color="inherit"
                            size="small"
                            onClick={() => navigate('/login')}
                            endIcon={<TbArrowRight />}
                          >
                            Log In
                          </Button>
                        )}
                      >
                        Your password has been reset. You can now log in with your new password.
                      </Alert>
                    ) : (
                      <>
                        <label>
                          New Password
                          <span className="required">*</span>
                        </label>
                        <input
                          value={password}
                          type="password"
                          placeholder="Password..."
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label style={{ marginTop: 20 }}>
                          Confirm New Password
                          <span className="required">*</span>
                        </label>
                        <input
                          value={passwordConfirm}
                          type="password"
                          placeholder="Confirm Password..."
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                        <Button
                          className="btn btn-purple"
                          sx={{ mt: 3 }}
                          onClick={resetPassword}
                        >
                          Reset Password
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <label>
                  Email Addreess
                  <span className="required">*</span>
                </label>
                <input
                  value={email}
                  type="email"
                  placeholder="E.g. jeroen@svcover.nl"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  className="btn btn-purple"
                  sx={{ mt: 3 }}
                  onClick={sendResetEmail}
                  disabled={loading}
                >
                  Send password reset email
                </Button>
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default PasswordReset;
