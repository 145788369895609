import React from 'react';
import HomePage from '../Views/HomePage';
import LoginView from '../Views/Login';
import CreateAccount from '../Views/CreateAccount';
import ConfirmEmail from '../Views/ConfirmEmail';
import Account from '../Views/Account';
import SignUp from '../Views/SignUp';
import NotFound from '../Views/NotFound';
import Payment from '../Views/Payment';
import TopUp from '../Views/TopUp';
import TermsAndConditions from '../Views/TermsAndConditions';
import PasswordReset from '../Views/PasswordReset';

export default function Pages() {
  return {
    home: {
      id: 1, path: '/', loginNeeded: false, showMenu: true, element: <HomePage />,
    },
    createAccount: {
      id: 2, path: '/create-account', loginNeeded: false, showMenu: false, element: <CreateAccount />,
    },
    createAccountType: {
      id: 3, path: '/create-account/:accountType', loginNeeded: false, showMenu: false, element: <CreateAccount />,
    },
    login: {
      id: 4, showMenu: false, path: '/login', element: <LoginView />,
    },
    confirmEmail: {
      id: 5, path: '/confirm-email/:code', loginNeeded: false, showMenu: false, element: <ConfirmEmail />,
    },
    account: {
      id: 6, path: '/account', loginNeeded: true, showMenu: true, element: <Account />,
    },
    signUp: {
      id: 7, path: '/signup', loginNeeded: true, showMenu: false, element: <SignUp />,
    },
    payment: {
      id: 8, path: '/payment/:id', loginNeeded: true, showMenu: false, element: <Payment />,
    },
    topUp: {
      id: 6, path: '/topup', loginNeeded: true, showMenu: true, element: <TopUp />,
    },
    termsAndConditions: {
      id: 7, path: '/terms-and-conditions', loginNeeded: false, showMenu: true, element: <TermsAndConditions />,
    },
    passwordReset: {
      id: 8, path: '/reset-password', loginNeeded: false, showMenu: true, element: <PasswordReset />,
    },
    resetPassword: {
      id: 9, path: '/reset-password/:token', loginNeeded: false, showMenu: true, element: <PasswordReset />,
    },
    notFound: {
      id: 21, path: '*', loginNeeded: false, showMenu: false, element: <NotFound />,
    },
  };
}
