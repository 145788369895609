import React from 'react';
import { Alert, Button } from '@mui/material';
import { TbAlertSquareRounded, TbArrowRight, TbChecks } from 'react-icons/tb';
import PropTypes from 'prop-types';

function SignUpResponse({ response }) {
  return (
    <>
      {response.signup_weekend && response.signup_weekend.status === 'PENDING_APPROVAL' && (
        <Alert
          severity="success"
          icon={<TbAlertSquareRounded />}
          sx={{ mb: 2 }}
        >
          Your signup for the Introduction Weekend has been received and is pending approval.
          You will receive an email once your signup has been approved. If you must pay for
          the weekend, when paying, if you have already signed up for the Introduction
          Barbecue, you will not be charged the commission fee again.
        </Alert>
      )}
      {response.signup_weekend && response.signup_weekend.status === 'CONFIRMED' && (
        <Alert
          severity="success"
          icon={<TbChecks />}
          sx={{ mb: 2 }}
        >
          You are now successfully signed up for the Introduction Weekend.
        </Alert>
      )}
      {response.signup_barbecue && response.signup_barbecue.status === 'CONFIRMED' && (
        <Alert
          severity="success"
          icon={<TbChecks />}
          sx={{ mb: 2 }}
        >
          You are now successfully signed up for the Introduction Barbecue.
        </Alert>
      )}
      {response.payment && (
        <>
          <h2>Confirm Payment</h2>
          <p>
            After clicking the button below, you will be redirected to the payment page.
          </p>
          <Button
            className="btn btn-purple"
            startIcon={<TbArrowRight />}
            // eslint-disable-next-line
            href={response.payment.details._links.checkout.href}
          >
            Continue and Pay €
            {' '}
            {response.payment.details.amount.value}
          </Button>
          {response.payment.commission > 0 && (
          <p>
            <small>
              incl. €
              {response.payment.commission}
              {' '}
              transaction fee.
            </small>
          </p>
          )}
        </>
      )}
    </>
  );
}

SignUpResponse.propTypes = {
  // eslint-disable-next-line
  response: PropTypes.object.isRequired,
};

export default SignUpResponse;
