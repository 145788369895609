import React, { useEffect, useState } from 'react';
import {
  Alert, Button, CircularProgress, Container,
} from '@mui/material';
import {
  TbAlertSquareRounded, TbArrowRight, TbSquareRounded, TbSquareRoundedCheck,
} from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import PageHeader from '../../Components/PageHeader';
import IntroCeeAPI from '../../APIs/IntroCeeAPI';
import CreateAccountForm from '../../Components/CreateAccountForm';

function CreateAccount() {
  const [type, setType] = useState(null);
  const [session, setSession] = useState(null);
  const api = IntroCeeAPI();
  const { accountType } = useParams();
  const BLANK_FORM_DATA = {
    first_name: '',
    last_name: '',
    infix: '',
    email: '',
    phone: '+31',
    password: '',
    password_confirmation: '',
    study_programme: '',
    study_phase: '',
    student_number: '',
    study_year: 1,
    dietary_requirements: '',
    remarks: '',
    emergency_contact: '',
    emergency_contact_phone: '+31',
    drivers_license: false,
    mentor: false,
    terms_and_conditions: false,
    date_of_birth: dayjs(new Date()),
  };
  const [formData, setFormData] = useState(BLANK_FORM_DATA);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (type === 'senior') {
      api.getCoverSession().then((res) => {
        setSession(res.data);
      });
    }
    if (type === 'first_year') {
      setFormData(BLANK_FORM_DATA);
    }
  }, [type]);

  useEffect(() => {
    if (accountType === 'first_year' || accountType === 'senior') {
      setType(accountType);
    }
  }, [accountType]);

  const submit = () => {
    if (formData.first_name === ''
      || formData.last_name === ''
      || formData.email === ''
      || formData.phone === ''
      || formData.password === ''
      || formData.password_confirmation === ''
      || formData.study_programme === ''
      || formData.study_year === ''
      || formData.study_phase === ''
      || formData.emergency_contact === ''
      || formData.emergency_contact_phone === ''
      || formData.date_of_birth === ''
      || (type === 'first_year' && formData.student_number === '')
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill in all required fields!',
      });
      return;
    }

    if (formData.password !== formData.password_confirmation) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'The passwords do not match!',
      });
      return;
    }

    if (!formData.terms_and_conditions) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please read and agree to the terms and conditions!',
      });
      return;
    }

    setLoading(true);
    api.createAccount({
      first_name: formData.first_name,
      infix: formData.infix,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      study_programme: `${formData.study_phase} ${formData.study_programme}`,
      study_year: type === 'senior' ? formData.study_year : 1,
      dietary_requirements: formData.dietary_requirements,
      remarks: formData.remarks,
      emergency_contact: formData.emergency_contact,
      emergency_contact_phone: formData.emergency_contact_phone,
      date_of_birth: formData.date_of_birth.format('YYYY-MM-DD'),
      student_number: type === 'first_year' ? formData.student_number : null,
      membership_id: type === 'senior' ? session.member.id : null,
      drivers_license: formData.drivers_license,
      mentor: formData.mentor,
    }).then((res) => {
      if (res.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Account Created!',
          text: 'Your account has been created. Please check your email for a verification link.',
        }).then(() => {
          navigate('/login');
        });
        setFormData(BLANK_FORM_DATA);
      } else {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong. Please try again later.',
        });
      }
    }).catch((e) => {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Something went wrong. Please try again later or contact us if the problem persists. (${Object.values(e.response.data).join(' ')})`,
      });
    });
  };

  useEffect(() => {
    if (session && session.member && type === 'senior') {
      const formDataCopy = { ...formData };
      formDataCopy.first_name = session.member.voornaam || '';
      formDataCopy.last_name = session.member.achternaam || '';
      formDataCopy.infix = session.member.tussenvoegsel || '';
      formDataCopy.email = session.member.email || '';
      formDataCopy.phone = session.member.telefoonnummer || '+31';
      formDataCopy.date_of_birth = dayjs(session.member.geboortedatum) || dayjs(new Date());
      formDataCopy.membership_id = session.member.id || 0;
      formDataCopy.study_year = session.member.beginjaar ? 2024 - session.member.beginjaar + 1 : 1;
      setFormData(formDataCopy);
    }
  }, [session]);

  return (
    <div className="create-account">
      <PageHeader title="Create Account" />
      <Container maxWidth="lg">
        <div className="type-switch">
          <h2>I am a ...</h2>
          <div className="type-switch__buttons">
            <Button
              className="btn btn-vanilla"
              onClick={() => setType('first_year')}
              startIcon={type === 'first_year' ? <TbSquareRoundedCheck /> : <TbSquareRounded />}
              sx={{ mx: 2, mb: 2 }}
            >
              First-Year Student
            </Button>
            <Button
              className="btn btn-purple"
              onClick={() => setType('senior')}
              startIcon={type === 'senior' ? <TbSquareRoundedCheck /> : <TbSquareRounded />}
              sx={{ mx: 2, mb: 2 }}
            >
              Senior Cover Member
            </Button>
          </div>
        </div>
        {type && (
          <>
            {type === 'first_year' ? (
              <>
                <div style={{ marginTop: '60px' }} />
                {loading ? (
                  <CircularProgress />
                ) : (
                  <CreateAccountForm
                    setFormData={(x) => setFormData(x)}
                    formData={formData}
                    onSubmit={submit}
                  />
                )}
              </>
            ) : (
              <div className="senior">
                {session && !loading ? (
                  <>
                    {session.member ? (
                      <CreateAccountForm
                        setFormData={(x) => setFormData(x)}
                        formData={formData}
                        onSubmit={submit}
                        senior
                      />
                    ) : (
                      <Alert
                        icon={<TbAlertSquareRounded />}
                        severity="error"
                        action={(
                          <Button
                            color="inherit"
                            size="small"
                            endIcon={<TbArrowRight />}
                            href={session.login_url}
                          >
                            Go to the Cover Website
                          </Button>
                        )}
                      >
                        <b>You must be logged in on the Cover website.</b>
                        {' '}
                        We pre-fill some of the personal details of the seniors based on the Cover
                        database. Please log in on the Cover website and try again.
                      </Alert>
                    )}
                  </>
                ) : (
                  <CircularProgress />
                )}
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default CreateAccount;
