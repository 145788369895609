import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert, Button, CircularProgress, Container,
} from '@mui/material';
import { TbAlertSquareRounded, TbArrowRight, TbSquareRoundedCheck } from 'react-icons/tb';
import PageHeader from '../../Components/PageHeader';
import IntroCeeAPI from '../../APIs/IntroCeeAPI';

function ConfirmEmail() {
  const { code } = useParams();
  const [response, setResponse] = useState(null);
  const api = IntroCeeAPI();

  useEffect(() => {
    if (!response) {
      api.confirmEmail(code).then((res) => {
        setResponse(res);
      }).catch((err) => {
        setResponse(err);
      });
    }
  }, []);

  return (
    <div className="confirm-email">
      <PageHeader title="Confirm Email" />
      <Container maxWidth="lg">
        {response ? (
          <>
            {response.status === 200 ? (
              <Alert
                icon={<TbSquareRoundedCheck />}
                severity="success"
                action={(
                  <Button
                    color="inherit"
                    size="small"
                    endIcon={<TbArrowRight />}
                    href="/login"
                  >
                    Log In
                  </Button>
                )}
              >
                <b>Your email address has been confirmed.</b>
                {' '}
                You can now log in and sign up for activities.
              </Alert>
            ) : (
              <Alert
                icon={<TbAlertSquareRounded />}
                severity="success"
              >
                <b>Something went wrong.</b>
                {' '}
                {response.response.data.detail}
                {' '}
                If you would like to request a new confirmation email, please log in.
              </Alert>
            )}
          </>
        ) : (
          <CircularProgress />
        )}
      </Container>
    </div>
  );
}

export default ConfirmEmail;
