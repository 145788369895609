import React from 'react';
import { Box, Container } from '@mui/material';

function Footer() {
  return (
    <footer>
      <Container maxWidth="lg">
        <Box
          className="footer-box"
          justifyContent={{ xs: 'center', lg: 'space-between' }}
          flexDirection={{ xs: 'column', lg: 'row' }}
        >
          <Box textAlign={{ xs: 'center', lg: 'left' }} marginBottom={{ xs: 2, lg: 0 }}>
            Built with 🤍 by
            {' '}
            <a href="https://svcover.nl/profile?lid=2772" target="_blank" rel="noreferrer">Fabi 💙</a>
            .
          </Box>
          <Box textAlign={{ xs: 'center', lg: 'right' }}>
            <a
              href="https://sd.svcover.nl/Privacy%20Statement/Privacy%20statement.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
              Statement
            </a>
            <a href="/terms-and-conditions" rel="noreferrer">Terms & Conditions</a>
          </Box>
        </Box>
      </Container>
    </footer>
  );
}

export default Footer;
