import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from '@mui/material';
import {
  TbBrandInstagram, TbBrandWhatsapp, TbLayoutGrid,
  TbSettings, TbSquareRoundedPlus, TbUserSquareRounded, TbX,
} from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function Header() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('#')) {
      const element = document.getElementById(window.location.href.split('#')[1]);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [window.location.href]);

  return (
    <header className="header">
      <Container maxWidth="lg">
        <div className="header-inner">
          <div className="header-logo">
            {/* eslint-disable-next-line */}
            <img
              src="/logo_white_with_cover.png"
              alt="Cover Logo"
              className="logo"
              onClick={() => navigate('/')}
            />
          </div>
          <button
            className="header-burger"
            type="button"
            aria-label="Toggle navigation"
            onClick={() => setIsMenuOpen(true)}
          >
            <TbLayoutGrid />
          </button>
          <div className={`header-nav ${isMenuOpen ? 'open' : 'closed'}`}>
            <button
              className="header-close"
              type="button"
              aria-label="Close navigation"
              onClick={() => setIsMenuOpen(false)}
            >
              <TbX />
            </button>
            <a className="menu-link" href="/">Home</a>
            <a className="menu-link" href="/#weekend">Introduction Weekend</a>
            <a className="menu-link" href="/#barbecue">Barbecue</a>
            <a
              href="https://instagram.com/svcover"
              target="_blank"
              aria-label="Cover Instagram"
              style={{ marginRight: 10 }}
              rel="noreferrer"
              className="menu-link"
            >
              <span className="mobile-only">Instagram</span>
              <TbBrandInstagram />
            </a>
            <a
              href="https://chat.whatsapp.com/HWJxYU6p8abBocWeOpe34H"
              target="_blank"
              aria-label="Cover WhatsApp"
              rel="noreferrer"
              className="menu-link"
            >
              <span className="mobile-only">WhatsApp Community</span>
              <TbBrandWhatsapp />
            </a>
            {user && user.first_name ? (
              <>
                <div className="header-user">
                  <Button
                    className="btn btn-vanilla btn-icon"
                    href="/account"
                    sx={{ mr: '0 !important' }}
                  >
                    <TbUserSquareRounded />
                  </Button>
                  {/* eslint-disable-next-line */}
                  <div
                    className="user-info"
                    role="button"
                    onClick={() => navigate('/account')}
                  >
                    <span>{user.name}</span>
                    {user.email}
                  </div>
                </div>
                {(user.roles.find((r) => r.slug === 'introcee') || user.roles.find((r) => r.slug === 'board') || user.roles.find((r) => r.slug === 'candy') || user.roles.find((r) => r.slug === 'herocee')) && (
                  // eslint-disable-next-line
                  <a href={process.env.REACT_APP_BACKOFFICE_URL} className="backoffice-link">
                    <Button
                      className="btn btn-purple btn-icon"
                      sx={{ mr: '0 !important' }}
                    >
                      <TbSettings />
                    </Button>
                    <span className="mobile-only">Backoffice</span>
                  </a>
                )}
              </>
            ) : (
              <>
                <Button
                  className="btn btn-vanilla"
                  startIcon={<TbUserSquareRounded />}
                  sx={{ marginRight: '20px !important' }}
                  href="/login"
                >
                  Log In
                </Button>
                <Button
                  className="btn btn-purple"
                  startIcon={<TbSquareRoundedPlus />}
                  href="/create-account"
                >
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
