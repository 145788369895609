import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';

function PageHeader({ title }) {
  return (
    <div className="page-header">
      <Container maxWidth="lg">
        <h1>{title}</h1>
      </Container>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeader;
