import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.scss';
import { NotificationContainer } from 'react-notifications';
import { CookiesProvider } from 'react-cookie';
import history from './Utils/history';
import Pages from './Constants/pages';
import UserProvider from './Providers/UserProvider/UserProvider';
import NotificationProvider from './Providers/NotificationProvider/NotificationProvider';
import CustomRouter from './Utils/CustomRouters';
import AuthenticatedRoute from './Components/AuthenticatedRoute/AuthenticatedRoute';
import Header from './Components/Header';
import Footer from './Components/Footer';

function App() {
  const pages = Pages();

  return (
    <CookiesProvider>
      <UserProvider>
        <CustomRouter history={history}>
          <div className="App">
            <NotificationProvider>
              <>
                <NotificationContainer />
                <Header />
                <Routes>
                  {
                    Object.keys(pages).map((key, idx) => (
                      <Route
                        key={idx}
                        path={pages[key].path}
                        element={pages[key].loginNeeded
                          ? (
                            <AuthenticatedRoute
                              restricted={pages[key].restricted || false}
                              element={pages[key].element}
                            />
                          )
                          : pages[key].element}
                      />
                    ))
                  }
                </Routes>
              </>
            </NotificationProvider>
          </div>
          <Footer />
        </CustomRouter>
      </UserProvider>
    </CookiesProvider>
  );
}

export default App;
