import React, {
  useContext, useEffect, useReducer, useState,
} from 'react';
import {
  Alert, Button, CircularProgress, Container,
} from '@mui/material';
import {
  Navigate, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import LineIcon from 'react-lineicons';
import {
  TbAlertSquareRounded, TbArrowRight, TbInfoSquareRounded, TbKey,
} from 'react-icons/tb';
import Swal from 'sweetalert2';
import { LOGIN_URL, RESEND_CONFIRMATION_EMAIL_URL } from '../../Constants/URLS';
import apiUtils from '../../Utils/ApiUtils';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import loginReducer from './LoginReducer';
import PageHeader from '../../Components/PageHeader';

function LoginView() {
  const { storeTokens, tokens } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form, formDispatch] = useReducer(loginReducer, {
    fullFieldError: '',
    username: '',
    usernameError: '',
    password: '',
    passwordError: '',
    canSubmit: false,
    submitting: false,
  });
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [emailConfirmationSent, setEmailConfirmationSent] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const api = apiUtils();
  const [queryParameters] = useSearchParams();

  function tryLogin() {
    formDispatch({ type: 'SUBMIT', payload: true });
    setLoading(true);

    api.postNoAuth(LOGIN_URL, { email: form.username, password: form.password })
      .then((response) => {
        if (response?.status === 200) {
          formDispatch({ type: 'SUBMIT', payload: false });
          storeTokens(response.data.access, response.data.refresh);
          if (queryParameters.get('next')) {
            window.location.href = queryParameters.get('next');
          }
          if (location.state && location.state.from) {
            navigate(location.state.from);
          }
        } else {
          formDispatch({ type: 'HANDLE_ERROR', payload: { non_field_errors: response.data.detail } });
        }
        setLoading(false);
      }).catch((data) => {
        formDispatch({ type: 'SUBMIT', payload: false });
        if (data.response.status === 412) {
          setEmailNotVerified(true);
        } else if (data.response.status === 401) {
          formDispatch({ type: 'HANDLE_ERROR', payload: { non_field_errors: ['Invalid username or password.'] } });
        } else {
          formDispatch({ type: 'HANDLE_ERROR', payload: { non_field_errors: data.response.data } });
        }
        setLoading(false);
      });
  }

  useEffect(
    () => {
      if (form.canSubmit) tryLogin();
    },
    [form.canSubmit],
  );

  const sendConfirmationEmail = () => {
    api.postNoAuth(RESEND_CONFIRMATION_EMAIL_URL, { email: form.username })
      .then((response) => {
        if (response?.status === 200) {
          setEmailConfirmationSent(true);
          Swal.fire({
            icon: 'success',
            title: 'Email sent!',
            text: 'Please check your email for a verification link.',
          });
        }
      }).catch((data) => {
        if (data.response.status === 404) {
          formDispatch({ type: 'HANDLE_ERROR', payload: { non_field_errors: ['This email address is not registered.'] } });
        } else {
          formDispatch({ type: 'HANDLE_ERROR', payload: { non_field_errors: data.response.data } });
        }
      });
  };

  return (
    <div className="login">
      <PageHeader title="Log In" />
      {tokens?.accessToken === undefined || tokens.accessToken.length === 0 ? (
        <Container maxWidth="sm">
          {emailNotVerified ? (
            <Alert
              icon={<TbInfoSquareRounded />}
              severity="info"
              action={!emailConfirmationSent && (
                <Button
                  color="inherit"
                  size="small"
                  endIcon={<TbArrowRight />}
                  onClick={sendConfirmationEmail}
                >
                  Resend Confirmation Email
                </Button>
              )}
            >
              <b>Your email address is not verified.</b>
              {' '}
              Please check your email for a verification link.
              If you have not received an email, you can request another one.
              If the issue persists, please contact us.
            </Alert>
          ) : (
            <>
              <Alert
                icon={<TbInfoSquareRounded />}
                severity="warning"
                action={(
                  <Button
                    color="inherit"
                    size="small"
                    endIcon={<TbArrowRight />}
                    href="/create-account"
                  >
                    Create Account
                  </Button>
            )}
              >
                <b>This is not your Cover account.</b>
                {' '}
                If you have not done so already, you need to create a new account for this website.
              </Alert>
              <div className="login-form">
                {form.fullFieldError && (
                  <Alert
                    icon={<TbAlertSquareRounded />}
                    severity="error"
                    sx={{ mt: 2 }}
                  >
                    <b>{form.fullFieldError}</b>
                  </Alert>
                )}
                {form.usernameError && (
                  <Alert
                    icon={<TbAlertSquareRounded />}
                    severity="error"
                    sx={{ mt: 2 }}
                  >
                    <b>{form.usernameError}</b>
                  </Alert>
                )}
                <label>Email Address</label>
                <input
                  id="username-input"
                  value={form.username}
                  placeholder="E.g. jeroen@svcover.nl"
                  autoComplete="email"
                  onChange={(e) => formDispatch({ type: 'USERNAME', payload: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      formDispatch({ type: 'VALIDATE' });
                    }
                  }}
                />
                {form.passwordError && (
                  <Alert
                    icon={<TbAlertSquareRounded />}
                    severity="error"
                    sx={{ mt: 2 }}
                  >
                    <b>{form.passwordError}</b>
                  </Alert>
                )}
                <label>Password</label>
                <input
                  id="password-input"
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  value={form.password}
                  onChange={(e) => formDispatch({ type: 'PASSWORD', payload: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      formDispatch({ type: 'VALIDATE' });
                    }
                  }}
                />
                <a className="forgot-password" href="/reset-password">
                  <TbKey />
                  {' '}
                  I forgot my password
                </a>
                <div className="formActionsRow">
                  <Button
                    onClick={() => formDispatch({ type: 'VALIDATE' })}
                    className="btn btn-vanilla"
                    endIcon={!loading && <LineIcon name="arrow-right" />}
                    disabled={loading}
                    sx={{ mt: 3 }}
                  >
                    {!loading ? 'Login' : <CircularProgress color="inherit" sx={{ height: 'inherit' }} />}
                  </Button>
                </div>
              </div>
            </>
          )}
        </Container>
      ) : (
        <Navigate
          to={{
            pathname: '/account',
          }}
        />
      )}
    </div>
  );
}

LoginView.propTypes = {};

export default LoginView;
