import React, { useState } from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import { TbSquareRoundedCheck } from 'react-icons/tb';

function PackingList() {
  const [packingList, setPackingList] = useState(false);

  return (
    <>
      <Button
        className="btn btn-vanilla"
        startIcon={<TbSquareRoundedCheck />}
        sx={{ mt: 2 }}
        onClick={() => setPackingList(true)}
      >
        Packing List
      </Button>

      <Dialog
        onClose={() => setPackingList(false)}
        open={packingList}
        fullWidth
      >
        <DialogTitle>Packing List Introduction Weekend</DialogTitle>
        <DialogContent>
          <div className="packing-list">
            <b>Bedding</b>
            <div className="item">
              <span>🛏️</span>
              Duvet + Pillow Cover
            </div>
            <div className="item">
              <span>🛋️</span>
              Fitted Sheet
            </div>
            <b>Clothing</b>
            <div className="item">
              <span>👕</span>
              Clothes, socks and underwear for 3 days
            </div>
            <div className="item">
              <span>👖</span>
              Pair of long pants
            </div>
            <div className="item">
              <span>🧶</span>
              Sweater (in case of cold weather)
            </div>
            <div className="item">
              <span>🧢</span>
              Sun hat
            </div>
            <div className="item">
              <span>🩳</span>
              Old t-shirt and bottoms (to get dirty)
            </div>
            <div className="item">
              <span>💤</span>
              Pyjamas
            </div>
            <div className="item">
              <span>👟</span>
              Comfortable shoes
            </div>
            <div className="item">
              <span>🛍️</span>
              (Garbage) bag for dirty clothes
            </div>
            <div className="item">
              <span>☔</span>
              Rain Jacket/Poncho
            </div>
            <b>Optional (but very very nice)</b>
            <div className="item">
              <span>😎</span>
              Any kind of mafia clothing or accesories
            </div>
            <div className="item">
              <span>🪩</span>
              Any kind of neon/white clothing (for a neon party)
            </div>
            <b>Hygiene</b>
            <div className="item">
              <span>🚿</span>
              Towel + Washcloth
            </div>
            <div className="item">
              <span>🪥</span>
              Toothbrush + Toothpaste
            </div>
            <div className="item">
              <span>🧴</span>
              Shampoo, Soap, Shower Gel
            </div>
            <div className="item">
              <span>👃🏻</span>
              Deodorant
            </div>
            <div className="item">
              <span>🌞</span>
              Sunscreen
            </div>
            <div className="item">
              <span>🦟</span>
              Bugspray
            </div>
            <div className="item">
              <span>💊</span>
              Medicine (if applicable)
            </div>
            <b>Others</b>
            <div className="item">
              <span>💶</span>
              Wallet / Money (in case of emergencies)
            </div>
            <div className="item">
              <span>💧</span>
              Water bottle
            </div>
            <div className="item">
              <span>🪪</span>
              ID
            </div>
            <div className="item">
              <span>📱</span>
              Phone + Charger
            </div>
            <div className="item">
              <span>🎲</span>
              Any fun group board/card games (Optional)
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PackingList;
