import React, {
  createContext, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { mapToAuthRequest } from '../../Utils/RequestUtils';
import { REFRESH_URL, REQUEST_URL } from '../../Constants/URLS';

export const UserContext = createContext();
const FRONTEND_ACCESS_TOKEN = `introcee_${process.env.REACT_APP_ENVIRONMENT || 'prod'}_website_frontend_access_token`;
const FRONTEND_REFRESH_TOKEN = `introcee_${process.env.REACT_APP_ENVIRONMENT || 'prod'}_website_frontend_refresh_token`;

export default function UserProvider({ children }) {
  function getAccessTokenLocal() {
    return {
      accessToken: localStorage.getItem(FRONTEND_ACCESS_TOKEN) || '',
      refreshToken: localStorage.getItem(FRONTEND_REFRESH_TOKEN) || '',
    };
  }

  const [tokens, setTokens] = useState(getAccessTokenLocal());
  const [user, setUser] = useState({});

  function setLocalAccessToken({ accessToken, refreshToken }) {
    localStorage[FRONTEND_ACCESS_TOKEN] = accessToken;
    localStorage[FRONTEND_REFRESH_TOKEN] = refreshToken;
  }

  const getConfig = useCallback(async () => {
    const credential = getAccessTokenLocal();

    return {
      headers: {
        Authorization: `Bearer ${credential.accessToken}`,
      },
    };
  }, []);

  const storeTokens = useCallback((accessToken, refreshToken) => {
    setTokens({
      accessToken,
      refreshToken,
    });
    setLocalAccessToken({ accessToken, refreshToken });
  }, [setLocalAccessToken]);

  const logout = useCallback(() => {
    setTokens({
      accessToken: '',
      refreshToken: '',
    });
    setUser({});
    setLocalAccessToken({ accessToken: '', refreshToken: '' });
  }, [setLocalAccessToken]);

  function refresh() {
    const credential = getAccessTokenLocal();
    return axios
      .post(`${REFRESH_URL}`, { refresh: credential.refreshToken })
      .then((response) => {
        storeTokens(response.data.access, response.data.refresh);
        return {
          headers: {
            Authorization: `Bearer ${response.data.access}`,
          },
        };
      }).catch(() => {
        logout();
      });
  }

  const getUserInfo = useCallback(() => {
    const mapToAuthRequestLoc = (request) => mapToAuthRequest(request, getConfig, refresh, logout);
    const request = (config) => axios.get(`${REQUEST_URL}`, config);

    return mapToAuthRequestLoc(request)
      .then((result) => setUser(result.data));
  }, [getConfig, refresh, logout]);

  useEffect(() => {
    if (tokens.accessToken && tokens.refreshToken) {
      getUserInfo();
    }
  }, [tokens, getUserInfo]);

  const userData = {
    storeTokens,
    logout,
    getConfig,
  };

  return (
    <UserContext.Provider value={{
      ...userData, user, tokens, refresh,
    }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
