import React from 'react';
import propTypes from 'prop-types';

function Fact({ title, icon, value }) {
  return (
    <div className="fact">
      <div className="fact-icon">{icon}</div>
      <div className="fact-content">
        {value}
        <span>{title}</span>
      </div>
    </div>
  );
}

Fact.propTypes = {
  title: propTypes.string.isRequired,
  icon: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
};

export default Fact;
