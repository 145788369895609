import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { TbAlertSquareRounded } from 'react-icons/tb';
import { Alert, Container } from '@mui/material';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function AuthenticatedRoute({ element, restricted }) {
  const { user, tokens } = useContext(UserContext);

  return tokens?.accessToken?.length > 0 ? (
    <>
      {user ? (
        <>
          {restricted && !user.can_access_backoffice ? (
            <Container maxWidth="sm" sx={{ pt: 30 }}>
              <Alert
                icon={<TbAlertSquareRounded />}
                severity="error"
              >
                You do not have access to this part of the website.
              </Alert>
            </Container>
          ) : element}
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
      state={{ from: window.location.pathname }}
    />
  );
}

AuthenticatedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  restricted: PropTypes.bool,
};

AuthenticatedRoute.defaultProps = {
  restricted: false,
};

export default AuthenticatedRoute;
