import React from 'react';
import { Router } from 'react-router-dom'; // eslint-disable-next-line react/prop-types

// eslint-disable-next-line react/prop-types
function CustomRouter({ basename, children, history }) {
  const [state, setState] = React.useState({
    // eslint-disable-next-line react/prop-types
    action: history.action,
    // eslint-disable-next-line react/prop-types
    location: history.location,
  });

  // eslint-disable-next-line react/prop-types
  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Router
      basename={basename}
      /* eslint-disable-next-line react/no-children-prop */
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
      history={history}
    />
  );
}

export default CustomRouter;
