import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button, CircularProgress, Container, Grid, InputAdornment, TextField,
} from '@mui/material';
import {
  TbAlertSquareRounded, TbArrowRight, TbBus, TbGrill,
} from 'react-icons/tb';
import Moment from 'moment/moment';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import PageHeader from '../../Components/PageHeader';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import IntroCeeAPI from '../../APIs/IntroCeeAPI';
import SignUpResponse from '../../Components/SignUpResponse';

function SignUp() {
  const [weekend, setWeekend] = useState(false);
  const [barbecue, setBarbecue] = useState(false);
  const { user } = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [topUp, setTopUp] = useState(0.00);
  const [settings, setSettings] = useState(null);
  const api = IntroCeeAPI();
  const [barbecueDisabled, setBarbecueDisabled] = useState(false);
  const [weekendDisabled, setWeekendDisabled] = useState(false);
  const [response, setResponse] = useState(null);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const weekendUrl = searchParams.get('weekend'); // "true" if URL is ?something=true

  useEffect(() => {
    if (user && user.name) {
      if (user.is_signed_up_weekend) {
        setWeekendDisabled(true);
      }
      if (user.is_signed_up_barbecue) {
        setBarbecueDisabled(true);
      }

      api.settings().then((res) => {
        setSettings(res.data);
      });
    }
  }, [user]);

  useEffect(() => {
    if (settings) {
      if (new Date(settings.BARBECUE_SIGNUPS_OPEN) > new Date()
        || new Date(settings.BARBECUE_SIGNUPS_CLOSED) < new Date()) {
        setBarbecueDisabled(true);
      }
      if (new Date(settings.WEEKEND_SIGNUPS_OPEN) > new Date()
        || new Date(settings.WEEKEND_SIGNUPS_CLOSED) < new Date()) {
        setWeekendDisabled(true);
      }
    }
  }, [settings]);

  useEffect(() => {
    if (step === 3) {
      api.signUp({
        weekend, barbecue, topup: parseFloat(topUp).toFixed(2),
      }).then((res) => {
        setResponse(res.data);
      }).catch((e) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: e.response.data.detail,
        });
        setStep(2);
      });
    }
  }, [step]);

  useEffect(() => {
    if (weekendUrl === 'true') {
      setWeekend(true);
    }
  }, [weekendUrl]);

  useEffect(() => {
    if (weekend && weekendDisabled) {
      setWeekend(false);
    }
  }, [weekendDisabled]);

  return settings && user && user.name && (
    <div className="signup">
      <PageHeader title="Sign Up" />
      <Container maxWidth="lg">
        {step === 1 && (
          <>
            {new Date(settings.BARBECUE_SIGNUPS_OPEN) > new Date() && (
              <Alert
                severity="warning"
                icon={<TbAlertSquareRounded />}
                sx={{ mb: 2 }}
              >
                Barbecue signups are not open yet. They will open on
                {' '}
                {Moment(settings.BARBECUE_SIGNUPS_OPEN).format('YYYY-MM-DD HH:mm')}
                .
              </Alert>
            )}
            {new Date(settings.BARBECUE_SIGNUPS_CLOSED) < new Date() && (
              <Alert
                severity="warning"
                icon={<TbAlertSquareRounded />}
                sx={{ mb: 2 }}
              >
                Barbecue signups are closed.
              </Alert>
            )}
            {new Date(settings.WEEKEND_SIGNUPS_OPEN) > new Date() && (
              <Alert
                severity="warning"
                icon={<TbAlertSquareRounded />}
                sx={{ mb: 2 }}
              >
                Weekend signups are not open yet. They will open on
                {' '}
                {Moment(settings.WEEKEND_SIGNUPS_OPEN).format('YYYY-MM-DD HH:mm')}
              </Alert>
            )}
            {new Date(settings.WEEKEND_SIGNUPS_CLOSED) < new Date() && (
              <Alert
                severity="warning"
                icon={<TbAlertSquareRounded />}
                sx={{ mb: 2 }}
              >
                Weekend signups are closed.
              </Alert>
            )}
            <h2>Sign up for ...</h2>
            <p>
              You can select more than one activity at the same time.
              This way, you avoid having to pay the commission twice.
            </p>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                {/* eslint-disable-next-line */}
                <div
                  className={`box signup-button ${weekend ? 'active' : ''} ${weekendDisabled ? 'disabled' : ''}`}
                  onClick={() => setWeekend(!weekend && !weekendDisabled)}
                >
                  <TbBus />
                  <span>
                    Introduction Weekend
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* eslint-disable-next-line */}
                <div
                  className={`box signup-button ${barbecue ? 'active' : ''} ${barbecueDisabled ? 'disabled' : ''}`}
                  onClick={() => setBarbecue(!barbecue && !barbecueDisabled)}
                >
                  <TbGrill />
                  <span>
                    Introduction Barbecue
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} lg={12} sx={{ textAlign: 'center' }}>
                <Button
                  className="btn btn-purple"
                  startIcon={<TbArrowRight />}
                  disabled={!weekend && !barbecue}
                  onClick={() => setStep(2)}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {step === 2 && (
          <>
            <h2>Would you like to top up your wallet?</h2>
            <p>
              During the weekend & barbecue you will only be able to pay at the bar
              using your wallet, so make sure to top up in advance. At the end of
              the introduction period, we will reimburse all funds left over in your
              wallet. You can avoid to pay multiple €0.5 commissions if you top up more
              in the beginning.
            </p>
            <label>Amount</label>
            <TextField
              className="amount-input"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                inputProps: { min: 0, step: 0.5 },
              }}
              value={topUp}
              onChange={(e) => setTopUp(e.target.value)}
            />
            <Button
              className="btn btn-purple"
              startIcon={<TbArrowRight />}
              onClick={() => setStep(3)}
              sx={{ mt: 3 }}
            >
              Continue
            </Button>
          </>
        )}
        {step === 3 && (
          <>
            {response ? <SignUpResponse response={response} /> : <CircularProgress />}
          </>
        )}
      </Container>
    </div>
  );
}

export default SignUp;
